<template>
  <v-hover v-slot="{ hover }">
    <div class="gs-list-item" @click="onClick" :class="{ 'gs-list-item--active': active }">
      <div class="d-flex align-center">
        <div class="gs-header">
          <div
            class="gs-title ml-3"
            :class="{ 'gs-title--hover': hover }"
            @click="otherInfo = !otherInfo"
          >
            <abbr :title="`${otherInfo ? 'Hide' : 'Show'} metadata and source`">
              {{ value.data.key }}
            </abbr>
          </div>
        </div>
        <v-spacer></v-spacer>
        <div>
          <v-btn :style="{ display: hover ? '' : 'none' }" icon @click="$emit('close')"
            ><v-icon> mdi-close </v-icon></v-btn
          >
          <base-prop-tooltip
            :tooltip="active ? 'Hide layer' : 'Show layer'"
            :color="active ? 'primary' : ''"
          >
            <v-btn icon @click="$emit('click')" :color="active ? 'primary' : ''"
              ><v-icon>{{
                active ? ICONS.activeGeoscript : ICONS.inactiveGeoscript
              }}</v-icon></v-btn
            >
          </base-prop-tooltip>
        </div>
      </div>
      <v-expand-transition>
        <div v-if="otherInfo">
          <div class="d-flex mt-n2 ml-3 flex-wrap">
            <v-btn color="info" text class="text-capitalize" small @click="metadata = !metadata">
              Metadata
            </v-btn>
            <GeoscriptSourceDialog
              v-if="isAdmin"
              :geoscript="value.data.geoscript"
              :metadata="value.data.metadata"
            >
              <template #activator="{ show }">
                <v-btn
                  @click="show()"
                  v-if="isAdmin"
                  class="mx-1 text-capitalize"
                  small
                  text
                  color="success"
                  >Source</v-btn
                >
              </template>
            </GeoscriptSourceDialog>
            <slot name="button-after" :value="value"></slot>
            <v-spacer></v-spacer>
          </div>
          <v-expand-transition>
            <div class="metadata-text" v-show="metadata">
              <GeoscriptMetadata :data="value.data" />
              <div v-if="value.data.properties" class="mt-1 d-flex flex-wrap">
                <div v-if="value.data.properties.area">
                  <strong> Area: </strong>
                  <span>
                    {{ acresToSqft(value.data.properties.area) }} sqft
                    <span class="ml-2"> {{ toFixed(value.data.properties.area, 2) }} ac </span>
                  </span>
                </div>
                <v-spacer></v-spacer>
                <div v-if="value.data.properties.length">
                  <strong class="text-capitalize"> Length: </strong>
                  <span> {{ toFixed(value.data.properties.length, 2) }} ft </span>
                </div>
                <div>
                  <div>
                    <strong> bbox: </strong>
                    {{ value.data.properties.bbox }}
                  </div>
                </div>
              </div>
            </div>
          </v-expand-transition>
        </div>
      </v-expand-transition>
    </div>
  </v-hover>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    GeoscriptMetadata: () => import('./GeoscriptMetadata.vue'),
    GeoscriptSourceDialog: () => import('./GeoscriptSourceDialog.vue')
  },
  data() {
    return {
      metadata: false,
      ICONS: {
        activeGeoscript: 'mdi-eye',
        inactiveGeoscript: 'mdi-eye-outline'
      },
      otherInfo: false
    };
  },
  methods: {
    toFixed(value, decimal) {
      if (isNaN(value)) return '';
      return (+Number(value).toFixed(decimal)).toLocaleString();
    },
    acresToSqft(value) {
      return (+(value * 43560).toFixed(0)).toLocaleString();
    },
    onClick(e) {
      const classes = Array(...e.target.classList);
      if (classes.includes('spacer') || classes.includes('align-center')) {
        this.$emit('click');
      }
    }
  }
};
</script>

<style scoped lang="scss">
$border-color: #1976d211;
$border-color-hover: #1976d208;
$hover-color: #1976d209;
$active-color: #1976d215;
$hover-text-color: #012140;

.gs-list-item {
  padding: 5px;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;

  .gs-title {
    color: rgb(77, 73, 73);
    cursor: pointer;
  }

  &:hover {
    background-color: $hover-color;
    border-top: 1px solid $border-color-hover;
    border-bottom: 1px solid $border-color-hover;
  }
}

.gs-header {
  :hover {
    color: $hover-text-color !important;
    font-weight: 500;
  }
}

.gs-list-item--active {
  background-color: $active-color !important;
  border-top: 1px solid $border-color-hover;
  border-bottom: 1px solid $border-color-hover;
}

.metadata-text {
  color: grey;
  font-size: 0.8rem;
  margin-left: 2px;
  margin-right: 2px;
}

.d-flex {
  cursor: pointer;
}

abbr {
  text-decoration: none;
}
</style>
