import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"gs-list-item",class:{ 'gs-list-item--active': _vm.active },on:{"click":_vm.onClick}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"gs-header"},[_c('div',{staticClass:"gs-title ml-3",class:{ 'gs-title--hover': hover },on:{"click":function($event){_vm.otherInfo = !_vm.otherInfo}}},[_c('abbr',{attrs:{"title":((_vm.otherInfo ? 'Hide' : 'Show') + " metadata and source")}},[_vm._v(" "+_vm._s(_vm.value.data.key)+" ")])])]),_c(VSpacer),_c('div',[_c(VBtn,{style:({ display: hover ? '' : 'none' }),attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c(VIcon,[_vm._v(" mdi-close ")])],1),_c('base-prop-tooltip',{attrs:{"tooltip":_vm.active ? 'Hide layer' : 'Show layer',"color":_vm.active ? 'primary' : ''}},[_c(VBtn,{attrs:{"icon":"","color":_vm.active ? 'primary' : ''},on:{"click":function($event){return _vm.$emit('click')}}},[_c(VIcon,[_vm._v(_vm._s(_vm.active ? _vm.ICONS.activeGeoscript : _vm.ICONS.inactiveGeoscript))])],1)],1)],1)],1),_c(VExpandTransition,[(_vm.otherInfo)?_c('div',[_c('div',{staticClass:"d-flex mt-n2 ml-3 flex-wrap"},[_c(VBtn,{staticClass:"text-capitalize",attrs:{"color":"info","text":"","small":""},on:{"click":function($event){_vm.metadata = !_vm.metadata}}},[_vm._v(" Metadata ")]),(_vm.isAdmin)?_c('GeoscriptSourceDialog',{attrs:{"geoscript":_vm.value.data.geoscript,"metadata":_vm.value.data.metadata},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var show = ref.show;
return [(_vm.isAdmin)?_c(VBtn,{staticClass:"mx-1 text-capitalize",attrs:{"small":"","text":"","color":"success"},on:{"click":function($event){return show()}}},[_vm._v("Source")]):_vm._e()]}}],null,true)}):_vm._e(),_vm._t("button-after",null,{"value":_vm.value}),_c(VSpacer)],2),_c(VExpandTransition,[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.metadata),expression:"metadata"}],staticClass:"metadata-text"},[_c('GeoscriptMetadata',{attrs:{"data":_vm.value.data}}),(_vm.value.data.properties)?_c('div',{staticClass:"mt-1 d-flex flex-wrap"},[(_vm.value.data.properties.area)?_c('div',[_c('strong',[_vm._v(" Area: ")]),_c('span',[_vm._v(" "+_vm._s(_vm.acresToSqft(_vm.value.data.properties.area))+" sqft "),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.toFixed(_vm.value.data.properties.area, 2))+" ac ")])])]):_vm._e(),_c(VSpacer),(_vm.value.data.properties.length)?_c('div',[_c('strong',{staticClass:"text-capitalize"},[_vm._v(" Length: ")]),_c('span',[_vm._v(" "+_vm._s(_vm.toFixed(_vm.value.data.properties.length, 2))+" ft ")])]):_vm._e(),_c('div',[_c('div',[_c('strong',[_vm._v(" bbox: ")]),_vm._v(" "+_vm._s(_vm.value.data.properties.bbox)+" ")])])],1):_vm._e()],1)])],1):_vm._e()])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }